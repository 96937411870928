@import "~antd/dist/antd.css";
.entry-form {
  max-width: 800px;
  margin: auto;
  padding: 5px;
}
.introduction-text {
  font-size: large;
  color: white;
}
.ant-alert-error {
  background-color: "#A40C19";
}
.totalAmount {
  background-color: aliceblue;
  padding: 15px;
  border-radius: 10px;
}
.messageBox {
  font-size: 1.25rem;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedService {
  background-color: aliceblue;
}

@keyframes rollout {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-in {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-20%);
    opacity: 0;
  }
}
.mainform {
  /* animation: 0.5s slide-in;
  animation-timing-function: ease-in;
  animation-fill-mode: backwards; */
}

.select-ticket-type {
  animation: 0.5s slide-in;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}

.introduction-banner {
  min-height: 100vh;

  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: repeating-linear-gradient(
    to right,
    #a62131 0,
    #a62131 9%,
    #74272d 9%,
    #74272d 18%,
    #002e51 18%,
    #002e51 20%
  );
  text-align: center;
}

.headshot {
  width: 100%;
  height: auto;
  max-width: 200px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  object-fit: cover;
}
.honorees {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.honoree-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: row;
}
/* mobile */
@media (max-width: 768px) {
  .introduction-banner {
    padding: 1rem;
  }
  .headshot {
    max-width: 150px;
  }
  .honorees {
    gap: 2px;
  }
  .honoree-container {
    width: 100%;
  }
}
